import React from 'react';
// import Popover from 'antd/lib/popover';
import Icon from '@ant-design/icons';

import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'cvat-canvas3d-wrapper';
import { ShapeType } from 'reducers';

import { SkeletonIcon } from 'icons';

import DrawShapePopoverContainer from 'containers/annotation-page/standard-workspace/controls-side-bar/draw-shape-popover';
// import withVisibilityHandling from './handle-popover-visibility';
import MyCustomPopover from './custom-popover';

export interface Props {
    canvasInstance: Canvas | Canvas3d;
    isDrawing: boolean;
    disabled: boolean;
}

// const CustomPopover = withVisibilityHandling(Popover, 'draw-skeleton');
function DrawSkeletonControl(props: Props): JSX.Element {
    const { canvasInstance, isDrawing, disabled } = props;
    // const dynamicPopoverProps = isDrawing ? {
    //     overlayStyle: {
    //         display: 'none',
    //     },
    // } : {};

    const dynamicIconProps = isDrawing ? {
        className: 'cvat-draw-skeleton-control cvat-active-canvas-control',
        onClick: (): void => {
            canvasInstance.draw({ enabled: false });
        },
    } : {
        className: 'cvat-draw-skeleton-control',
    };

    return disabled ? (
        <Icon className='cvat-draw-skeleton-control cvat-disabled-canvas-control' component={SkeletonIcon} />
    ) : (
        // <CustomPopover
        //     {...dynamicPopoverProps}
        //     overlayClassName='cvat-draw-shape-popover'
        //     placement='right'
        //     content={<DrawShapePopoverContainer shapeType={ShapeType.SKELETON} />}
        // >
        //     <Icon {...dynamicIconProps} component={SkeletonIcon} />
        // </CustomPopover>
        <MyCustomPopover
            shapeType={ShapeType.SKELETON}
            overlayClassName='cvat-draw-shape-popover'
            content={<DrawShapePopoverContainer shapeType={ShapeType.SKELETON} />}
        >
            <Icon {...dynamicIconProps} component={SkeletonIcon} />
        </MyCustomPopover>
    );
}

export default React.memo(DrawSkeletonControl);
