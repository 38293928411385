// Copyright (C) 2021-2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { connect } from 'react-redux';

import {
    CombinedState, AIModelType, Project, DatasetPreview, Task,
} from 'reducers';
import CreateTaskComponent from 'components/create-task-page/create-task-page';
import { CreateTaskData } from 'components/create-task-page/create-task-content';
import { createTaskAsync, getTasksAsync } from 'actions/tasks-actions';
import { getProjectDatasetPreviewsAsync, getProjectsOnlyAsync } from 'actions/projects-actions';

interface StateToProps {
    installedGit: boolean;
    dumpers:[];
    modelGroupsWithTypes: { [key: string]: AIModelType[] };
    currentProjects: Project[];
    currentDatasetPreviews: {
        [index: number]: DatasetPreview;
    };
    currentTasks: Task[];
}

interface DispatchToProps {
    onCreate: (data: CreateTaskData, onProgress?: (status: string) => void) => Promise<any>;
    getProject: (projectId: number) => Promise<any>;
    getProjectDatasetPreviews: (project: Project) => Promise<void>;
    getTask: (taskId: number) => Promise<any>;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onCreate: (data, onProgress) => dispatch(createTaskAsync(data, onProgress)),
        getProject: (projectId: number) => dispatch(getProjectsOnlyAsync({ id: projectId })),
        getProjectDatasetPreviews: (project: Project) => dispatch(getProjectDatasetPreviewsAsync(project)),
        getTask: (taskId: number) => dispatch(getTasksAsync({ id: taskId })),
    };
}

function mapStateToProps(state: CombinedState): StateToProps {
    return {
        installedGit: state.plugins.list.GIT_INTEGRATION,
        dumpers: state.formats.annotationFormats.dumpers,
        modelGroupsWithTypes: state.models.modelGroupsWithTypes,
        currentProjects: state.projects.current,
        currentDatasetPreviews: state.projects.datasetPreviews,
        currentTasks: state.tasks.current,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTaskComponent);
