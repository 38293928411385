// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022-2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd/lib/grid';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Select, { BaseOptionType } from 'antd/lib/select';
import Tag from 'antd/lib/tag';
import Text from 'antd/lib/typography/Text';
// import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';
import Switch from 'antd/lib/switch';
import Input from 'antd/lib/input';
import Form, { RuleObject, RuleRender } from 'antd/lib/form';
import notification from 'antd/lib/notification';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

import {
    // ModelAttribute,
    StringObject,
} from 'reducers';

import CVATTooltip from 'components/common/cvat-tooltip';
// import { clamp } from 'utils/math';
import config from 'config';
import { getCore, MLModel, ModelKind, ModelReturnType, DimensionType, Label as LabelInterface } from 'cvat-core-wrapper';

const core = getCore();

interface Props {
    withCleanup: boolean;
    models: MLModel[];
    labels: LabelInterface[];
    dimension: DimensionType;
    // runInference(model: MLModel, body: object): void;
    runInference(body: object): void;
    taskModelTypeId: number;
    taskSize: number;
}

interface MappedLabel {
    name: string;
    attributes: StringObject;
}

type MappedLabelsList = Record<string, MappedLabel>;

export interface DetectorRequestBody {
    mapping: MappedLabelsList;
    cleanup: boolean;
    convMaskToPoly: boolean;
    startFrame: number;
    stopFrame: number;
}

interface Match {
    model: string | null;
    task: string | null;
}

interface FrameConfiguration {
    startFrame: number;
    stopFrame: number;
}

function DetectorRunner(props: Props): JSX.Element {
    const {
        // models,
        withCleanup,
        labels,
        // dimension,
        runInference,
        taskModelTypeId,
        taskSize,
    } = props;

    const initialValues: FrameConfiguration = {
        startFrame: 0,
        stopFrame: taskSize - 1,
    };

    const [form] = Form.useForm();

    // const [modelID, setModelID] = useState<string | null>(null);
    const [mapping, setMapping] = useState<MappedLabelsList>({});
    // const [threshold, setThreshold] = useState<number>(0.5);
    // const [distance, setDistance] = useState<number>(50);
    const [cleanup, setCleanup] = useState<boolean>(false);
    const [convertMasksToPolygons, setConvertMasksToPolygons] = useState<boolean>(false);
    const [match, setMatch] = useState<Match>({ model: null, task: null });
    const [attrMatches, setAttrMatch] = useState<Record<string, Match>>({});

    const [modelLabels, setModelLabels] = useState<string[]>([]);
    const [modelTypeName, setModelTypeName] = useState<string>('');

    // const model = models.filter((_model): boolean => _model.id === modelID)[0];
    // const isDetector = model && model.kind === ModelKind.DETECTOR;

    // if Task has model type id, default Task is using model of type 'detector'
    const isDetector = (taskModelTypeId && true);

    // const isReId = model && model.kind === ModelKind.REID;
    // const isClassifier = model && model.kind === ModelKind.CLASSIFIER;
    // const convertMasksToPolygonsAvailable = isDetector &&
    //     (!model.returnType || model.returnType === ModelReturnType.MASK);
    const convertMasksToPolygonsAvailable = isDetector;

    // const buttonEnabled =
    //     model && (model.kind === ModelKind.REID ||
    //         (model.kind === ModelKind.DETECTOR && !!Object.keys(mapping).length) ||
    //         (model.kind === ModelKind.CLASSIFIER && !!Object.keys(mapping).length));
    const buttonEnabled = (isDetector && !!Object.keys(mapping).length);

    // const canHaveMapping = isDetector || isClassifier;
    const canHaveMapping = isDetector;
    // const modelLabels = (canHaveMapping ? model.labels : []).filter((_label: string): boolean => !(_label in mapping));
    const taskLabels = canHaveMapping ? labels.map((label: any): string => label.name) : [];

    // if (model && model.kind === ModelKind.REID && !model.labels.length) {
    //     notification.warning({
    //         message: 'The selected model does not include any labels',
    //     });
    // }

    useEffect(() => {
        // get labels & name from model type
        core.aiModelTypes.get({ id: taskModelTypeId })
            .then((response: any) => {
                /** Sample response: 28 Mar 2023
                    [{
                        "id": 1,
                        "name": "type1",
                        "ai_model_groups": [
                            "group1",
                            "group2",
                            "group3",
                            "group5"
                        ],
                        "label_names": [
                            "face",
                            "eye",
                            "hand"
                        ]
                    }]
                    */
                // TODO refactor the code to Wrap response[0].label_names inside object like create Task does
                const retrievedModelLabels: string[] = response[0].label_names;
                setModelLabels(retrievedModelLabels);
                setModelTypeName(response[0].name);
                const defaultMapping = labels.reduce(
                    (acc: MappedLabelsList, label: LabelInterface): MappedLabelsList => {
                        if (retrievedModelLabels.includes(label.name)) {
                            acc[label.name] = {
                                name: label.name,
                                attributes: label.attributes.reduce((ac, item) => ({
                                    ...ac,
                                    [item.name]: item.name,
                                }), {}),
                            };
                        }
                        return acc;
                    }, {},
                );

                setMapping(defaultMapping);
                setMatch({ model: null, task: null });
            });
    }, []);

    // function matchAttributes(
    //     labelAttributes: LabelInterface['attributes'],
    //     modelAttributes: ModelAttribute[],
    // ): StringObject {
    //     if (Array.isArray(labelAttributes) && Array.isArray(modelAttributes)) {
    //         return labelAttributes
    //             .reduce((attrAcc: StringObject, attr: any): StringObject => {
    //                 if (modelAttributes.some((mAttr) => mAttr.name === attr.name)) {
    //                     attrAcc[attr.name] = attr.name;
    //                 }

    //                 return attrAcc;
    //             }, {});
    //     }

    //     return {};
    // }

    const isInteger = ({ min, max }: { min?: number; max?: number }) => (
        _: RuleObject,
        value?: number | string,
    ): Promise<void> => {
        if (typeof value === 'undefined' || value === '') {
            return Promise.resolve();
        }

        const intValue = +value;
        if (Number.isNaN(intValue) || !Number.isInteger(intValue)) {
            return Promise.reject(new Error('Value must be a positive integer'));
        }

        if (typeof min !== 'undefined' && intValue < min) {
            return Promise.reject(new Error(`Value must be more than ${min}`));
        }

        if (typeof max !== 'undefined' && intValue > max) {
            return Promise.reject(new Error(`Value must be less than ${max}`));
        }

        return Promise.resolve();
    };

    const validateStopFrame: RuleRender = ({ getFieldValue }): RuleObject => ({
        validator(_: RuleObject, value?: string | number): Promise<void> {
            if (typeof value !== 'undefined' && value !== '') {
                const startFrame = getFieldValue('startFrame');
                if (typeof startFrame !== 'undefined' && startFrame !== '') {
                    if (+startFrame > +value) {
                        return Promise.reject(new Error('Start frame must not be more than stop frame'));
                    }
                }

                if (+value > (taskSize - 1)) {
                    return Promise.reject(new Error('Stop frame must be smaller than total frames of task'));
                }
            }

            return Promise.resolve();
        },
    });

    function updateMatch(modelLabel: string | null, taskLabel: string | null): void {
        function addMatch(modelLbl: string, taskLbl: string): void {
            const newMatch: MappedLabelsList = {};
            // const label = labels.find((l) => l.name === taskLbl) as LabelInterface;
            // const currentModel = models.filter((_model): boolean => _model.id === modelID)[0];
            // const attributes = matchAttributes(label.attributes, currentModel.attributes[modelLbl]);
            const attributes = {}; // TODO: enhance this later, ignore attributes for now

            newMatch[modelLbl] = { name: taskLbl, attributes };
            setMapping({ ...mapping, ...newMatch });
            setMatch({ model: null, task: null });
        }

        if (match.model && taskLabel) {
            addMatch(match.model, taskLabel);
            return;
        }

        if (match.task && modelLabel) {
            addMatch(modelLabel, match.task);
            return;
        }

        setMatch({
            model: modelLabel,
            task: taskLabel,
        });
    }

    // function updateAttrMatch(modelLabel: string, modelAttrLabel: string | null, taskAttrLabel: string | null): void {
    //     function addAttributeMatch(modelAttr: string, attrLabel: string): void {
    //         const newMatch: StringObject = {};
    //         newMatch[modelAttr] = attrLabel;
    //         mapping[modelLabel].attributes = { ...mapping[modelLabel].attributes, ...newMatch };

    //         delete attrMatches[modelLabel];
    //         setAttrMatch({ ...attrMatches });
    //     }

    //     const modelAttr = attrMatches[modelLabel]?.model;
    //     if (modelAttr && taskAttrLabel) {
    //         addAttributeMatch(modelAttr, taskAttrLabel);
    //         return;
    //     }

    //     const taskAttrModel = attrMatches[modelLabel]?.task;
    //     if (taskAttrModel && modelAttrLabel) {
    //         addAttributeMatch(modelAttrLabel, taskAttrModel);
    //         return;
    //     }

    //     attrMatches[modelLabel] = {
    //         model: modelAttrLabel,
    //         task: taskAttrLabel,
    //     };
    //     setAttrMatch({ ...attrMatches });
    // }

    function renderMappingRow(
        color: string,
        leftLabel: string,
        rightLabel: string,
        removalTitle: string,
        onClick: () => void,
        className = '',
    ): JSX.Element {
        return (
            <Row key={leftLabel} justify='start' align='middle'>
                <Col span={10} className={className}>
                    <Tag color={color}>{leftLabel}</Tag>
                </Col>
                <Col span={10} offset={1} className={className}>
                    <Tag color={color}>{rightLabel}</Tag>
                </Col>
                <Col offset={1}>
                    <CVATTooltip title={removalTitle}>
                        <DeleteOutlined
                            className='cvat-danger-circle-icon'
                            onClick={onClick}
                        />
                    </CVATTooltip>
                </Col>
            </Row>
        );
    }

    function renderSelector(
        value: string,
        tooltip: string,
        labelsToRender: string[],
        onChange: (label: string) => void,
        className = '',
    ): JSX.Element {
        return (
            <CVATTooltip title={tooltip} className={className}>
                <Select
                    value={value}
                    onChange={onChange}
                    style={{ width: '100%' }}
                    showSearch
                    filterOption={(input: string, option: BaseOptionType | undefined) => {
                        if (option) {
                            const { children } = option.props;
                            if (typeof children === 'string') {
                                return children.toLowerCase().includes(input.toLowerCase());
                            }
                        }

                        return false;
                    }}
                >
                    {labelsToRender.map(
                        (label: string): JSX.Element => (
                            <Select.Option value={label} key={label}>
                                {label}
                            </Select.Option>
                        ),
                    )}
                </Select>
            </CVATTooltip>
        );
    }

    return (
        // TODO: {TEMPLATE CODE - REMOVE LATER}
        // <div className='cvat-run-model-content'>
        //     <Row justify='start' align='middle'>
        //         <Text>Model Type: yolov7</Text>
        //     </Row>
        //     <Row align='middle' className='mt-10'>
        //         <Col span={10}>Model labels</Col>
        //         <Col span={10}>Task labels</Col>
        //     </Row>
        //     <Row align='middle' gutter={16} className='mt-10'>
        //         <Col span={10}>
        //             <Select
        //                 defaultValue='face'
        //                 style={{ width: 120 }}
        //                 options={[
        //                     { value: 'jack', label: 'Jack' },
        //                     { value: 'face', label: 'Face' },
        //                     { value: 'Yiminghe', label: 'yiminghe' },
        //                 ]}
        //             />
        //         </Col>
        //         <Col span={10}>
        //             <Select
        //                 defaultValue='face'
        //                 style={{ width: 120 }}
        //                 options={[
        //                     { value: 'jack', label: 'Jack' },
        //                     { value: 'face', label: 'Face' },
        //                     { value: 'Yiminghe', label: 'yiminghe' },
        //                 ]}
        //             />
        //         </Col>
        //         <Col span={4}>
        //             <Button type='primary'>Add</Button>
        //         </Col>
        //     </Row>
        //     {renderMappingRow('red', 'face', 'face', 'Remove the mapped label', () => {})}
        //     {renderMappingRow('blue', 'face', 'Face', 'Remove the mapped label', () => {})}
        //     <Row style={{ marginTop: 20 }}>
        //         <Col span={10}>
        //             <Text>Number of frames:</Text>
        //         </Col>
        //         <Col>
        //             <InputNumber
        //                 min={1}
        //                 max={100}
        //                 defaultValue={30}
        //             />
        //         </Col>
        //     </Row>
        //     <Row style={{ marginTop: 20 }}>
        //         <Col span={10}>
        //             <Text>Score threshold:</Text>
        //         </Col>
        //         <Col>
        //             <InputNumber
        //                 min={1}
        //                 max={100}
        //                 defaultValue={90}
        //             />
        //         </Col>
        //     </Row>
        //     <Row justify='end' align='middle' gutter={16}>
        //         <Col>
        //             <Button>Cancel</Button>
        //         </Col>
        //         <Col>
        //             <Button type='primary'>Submit</Button>
        //         </Col>
        //     </Row>
        // </div>
        // {RESERVED FOR FUTURE DEVELOPMENT}
        <Form form={form} layout='vertical' initialValues={initialValues}>
            <div className='cvat-run-model-content'>
                {/* <Row align='middle'>
                    <Col span={4}>Model:</Col>
                    <Col span={20}>
                        <Select
                            placeholder={dimension === DimensionType.DIMENSION_2D ? 'Select a model' : 'No models available'}
                            disabled={dimension !== DimensionType.DIMENSION_2D}
                            style={{ width: '100%' }}
                            onChange={(_modelID: string): void => {
                                const chosenModel = models.filter((_model): boolean => _model.id === _modelID)[0];
                                const defaultMapping = labels.reduce(
                                    (acc: MappedLabelsList, label: LabelInterface): MappedLabelsList => {
                                        if (chosenModel.labels.includes(label.name)) {
                                            acc[label.name] = {
                                                name: label.name,
                                                attributes: matchAttributes(
                                                    label.attributes, chosenModel.attributes[label.name],
                                                ),
                                            };
                                        }
                                        return acc;
                                    }, {},
                                );

                                setMapping(defaultMapping);
                                setMatch({ model: null, task: null });
                                setAttrMatch({});
                                setModelID(_modelID);
                            }}
                        >
                            {models.map(
                                (_model: MLModel): JSX.Element => (
                                    <Select.Option value={_model.id} key={_model.id}>
                                        {_model.name}
                                    </Select.Option>
                                ),
                            )}
                        </Select>
                    </Col>
                </Row> */}
                <Row align='middle'>
                    <Col span={6}>Model:</Col>
                    <Col span={18}><Text strong>{modelTypeName}</Text></Col>
                </Row>
                <Row align='middle'>
                    <Col span={6}>Total frames:</Col>
                    <Col span={18}><Text strong>{taskSize}</Text></Col>
                </Row>
                <Row justify='start'>
                    <Col span={10}>
                        <Form.Item
                            label='From frame'
                            name='startFrame'
                            rules={[
                                {
                                    required: true,
                                    message: 'The field is required.',
                                },
                                { validator: isInteger({ min: 0 }) },
                            ]}
                            style={{ marginBottom: 12, marginTop: 12 }}
                        >
                            <Input size='large' type='number' min={0} step={1} />
                        </Form.Item>
                    </Col>
                    <Col span={10} offset={1}>
                        <Form.Item
                            label='To frame'
                            name='stopFrame'
                            dependencies={['startFrame']}
                            rules={[
                                {
                                    required: true,
                                    message: 'The field is required.',
                                },
                                { validator: isInteger({ min: 0 }) },
                                validateStopFrame,
                            ]}
                            style={{ marginBottom: 12, marginTop: 12 }}
                        >
                            <Input size='large' type='number' min={0} step={1} />
                        </Form.Item>
                    </Col>
                </Row>
                {canHaveMapping && !!taskLabels.length && !!modelLabels.length ? (
                    <>
                        <Row justify='start' align='middle'>
                            <Col span={10}>
                                <Text strong>Model labels</Text>
                            </Col>
                            <Col span={10} offset={1}>
                                <Text strong>Task labels</Text>
                            </Col>
                        </Row>
                        <Row justify='start' align='middle'>
                            <Col span={10}>
                                {renderSelector(match.model || '', 'Model labels', modelLabels, (modelLabel: string) => updateMatch(modelLabel, null))}
                            </Col>
                            <Col span={10} offset={1}>
                                {renderSelector(match.task || '', 'Task labels', taskLabels, (taskLabel: string) => updateMatch(null, taskLabel))}
                            </Col>
                            <Col span={1} offset={1}>
                                <CVATTooltip title='Specify a label mapping between model labels and task labels'>
                                    <QuestionCircleOutlined className='cvat-info-circle-icon' />
                                </CVATTooltip>
                            </Col>
                        </Row>
                    </>
                ) : null}
                {canHaveMapping &&
                    Object.keys(mapping).length ?
                    Object.keys(mapping).map((modelLabel: string) => {
                        const label = labels
                            .find((_label: LabelInterface): boolean => (
                                _label.name === mapping[modelLabel].name)) as LabelInterface;

                        const color = label ? label.color : config.NEW_LABEL_COLOR;
                        // const notMatchedModelAttributes = model.attributes[modelLabel]
                        //     .filter((_attribute: ModelAttribute): boolean => (
                        //         !(_attribute.name in (mapping[modelLabel].attributes || {}))
                        //     ));
                        // const taskAttributes = label.attributes.map((_attrLabel: any): string => _attrLabel.name);
                        return (
                            <React.Fragment key={modelLabel}>
                                {
                                    renderMappingRow(color,
                                        modelLabel,
                                        label.name,
                                        'Remove the mapped label',
                                        (): void => {
                                            const newMapping = { ...mapping };
                                            delete newMapping[modelLabel];
                                            setMapping(newMapping);

                                            const newAttrMatches = { ...attrMatches };
                                            delete newAttrMatches[modelLabel];
                                            setAttrMatch({ ...newAttrMatches });
                                        })
                                }
                                {
                                    Object.keys(mapping[modelLabel].attributes || {})
                                        .map((mappedModelAttr: string) => (
                                            renderMappingRow(
                                                config.NEW_LABEL_COLOR,
                                                mappedModelAttr,
                                                mapping[modelLabel].attributes[mappedModelAttr],
                                                'Remove the mapped attribute',
                                                (): void => {
                                                    const newMapping = { ...mapping };
                                                    delete mapping[modelLabel].attributes[mappedModelAttr];
                                                    setMapping(newMapping);
                                                },
                                                'cvat-run-model-label-attribute-block',
                                            )
                                        ))
                                }
                                {/* {notMatchedModelAttributes.length && taskAttributes.length ? (
                                    <Row justify='start' align='middle'>
                                        <Col span={10}>
                                            {renderSelector(
                                                attrMatches[modelLabel]?.model || '',
                                                'Model attr labels', notMatchedModelAttributes.map((l) => l.name),
                                                (modelAttrLabel: string) => updateAttrMatch(
                                                    modelLabel, modelAttrLabel, null,
                                                ),
                                                'cvat-run-model-label-attribute-block',
                                            )}
                                        </Col>
                                        <Col span={10} offset={1}>
                                            {renderSelector(
                                                attrMatches[modelLabel]?.task || '',
                                                'Task attr labels', taskAttributes,
                                                (taskAttrLabel: string) => updateAttrMatch(
                                                    modelLabel, null, taskAttrLabel,
                                                ),
                                                'cvat-run-model-label-attribute-block',
                                            )}
                                        </Col>
                                        <Col span={1} offset={1}>
                                            <CVATTooltip
                                                title='Specify an attribute mapping between
                                                model label and task label attributes'
                                            >
                                                <QuestionCircleOutlined className='cvat-info-circle-icon' />
                                            </CVATTooltip>
                                        </Col>
                                    </Row>
                                ) : null} */}
                            </React.Fragment>
                        );
                    }) : null}
                {convertMasksToPolygonsAvailable && (
                    <div className='detector-runner-convert-masks-to-polygons-wrapper'>
                        <Switch
                            checked={convertMasksToPolygons}
                            onChange={(checked: boolean) => {
                                setConvertMasksToPolygons(checked);
                            }}
                        />
                        <Text>Convert masks to polygons</Text>
                    </div>
                )}
                {isDetector && withCleanup ? (
                    <div className='detector-runner-clean-previous-annotations-wrapper'>
                        <Switch
                            checked={cleanup}
                            onChange={(checked: boolean): void => setCleanup(checked)}
                        />
                        <Text>Clean previous annotations</Text>
                    </div>
                ) : null}
                {/* {isReId ? (
                    <div>
                        <Row align='middle' justify='start'>
                            <Col>
                                <Text>Threshold</Text>
                            </Col>
                            <Col offset={1}>
                                <CVATTooltip title='Minimum similarity value for shapes that can be merged'>
                                    <InputNumber
                                        min={0.01}
                                        step={0.01}
                                        max={1}
                                        value={threshold}
                                        onChange={(value: number | undefined | string | null) => {
                                            if (typeof value !== 'undefined' && value !== null) {
                                                setThreshold(clamp(+value, 0.01, 1));
                                            }
                                        }}
                                    />
                                </CVATTooltip>
                            </Col>
                        </Row>
                        <Row align='middle' justify='start'>
                            <Col>
                                <Text>Maximum distance</Text>
                            </Col>
                            <Col offset={1}>
                                <CVATTooltip title='Maximum distance between shapes that can be merged'>
                                    <InputNumber
                                        placeholder='Threshold'
                                        min={1}
                                        value={distance}
                                        onChange={(value: number | undefined | string | null) => {
                                            if (typeof value !== 'undefined' && value !== null) {
                                                setDistance(+value);
                                            }
                                        }}
                                    />
                                </CVATTooltip>
                            </Col>
                        </Row>
                    </div>
                ) : null} */}
                <Row align='middle' justify='end'>
                    <Col>
                        <Button
                            className='cvat-inference-run-button'
                            disabled={!buttonEnabled}
                            type='primary'
                            onClick={() => {
                                // let requestBody: object = {};
                                // if (model.kind === ModelKind.DETECTOR) {
                                //     requestBody = {
                                //         mapping,
                                //         cleanup,
                                //         convMaskToPoly: convertMasksToPolygons,
                                //     };
                                // } else if (model.kind === ModelKind.REID) {
                                //     requestBody = {
                                //         threshold,
                                //         max_distance: distance,
                                //     };
                                // } else if (model.kind === ModelKind.CLASSIFIER) {
                                //     requestBody = {
                                //         mapping,
                                //     };
                                // }

                                // runInference(model, requestBody);

                                form.validateFields().then(() => {
                                    const detectorRequestBody: DetectorRequestBody = {
                                        mapping,
                                        cleanup,
                                        convMaskToPoly: convertMasksToPolygons,
                                        startFrame: form.getFieldValue('startFrame'),
                                        stopFrame: form.getFieldValue('stopFrame'),
                                    };

                                    runInference(
                                        detectorRequestBody,
                                    );
                                }).catch((error: Error | ValidateErrorEntity) => {
                                    notification.error({
                                        message: 'Running auto annotation failed',
                                        description: (error as ValidateErrorEntity).errorFields ?
                                            (error as ValidateErrorEntity).errorFields
                                                .map((field) => `${field.name} : ${field.errors.join(';')}`)
                                                .map((text: string): JSX.Element => <div>{text}</div>) :
                                            error.toString(),
                                    });
                                });
                            }}
                        >
                            Annotate
                        </Button>
                    </Col>
                </Row>
            </div>
        </Form>
    );
}

export default React.memo(DetectorRunner);
