// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Text from 'antd/lib/typography/Text';

import CreateProjectContent from './create-project-content';

function CreateProjectPageComponent(): JSX.Element {
    return (
        <>
            <Text className='cvat-title'>Create a new project</Text>
            <CreateProjectContent />
        </>
    );
}

export default React.memo(CreateProjectPageComponent);
