// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, {
    useCallback, useRef, useEffect,
} from 'react';

import Text from 'antd/lib/typography/Text';
import LabelForm from './label-form';
import { LabelOptColor, SkeletonConfiguration } from './common';
import SkeletonConfigurator from './skeleton-configurator';
import ConstructorCreatorViewerItem from './constructor-create-label-viewer-item';

interface Props {
    labelNames: string[];
    creatorType: 'basic' | 'skeleton';
    onCreate: (label: LabelOptColor) => void;
    onCancel: () => void;
    labels: LabelOptColor[];
    onUpdate: (label: LabelOptColor) => void;
    onDelete: (label: LabelOptColor) => void;
    fullOptionsAsProps?: string[];
}

function compareProps(prevProps: Props, nextProps: Props): boolean {
    return (
        prevProps.onCreate === nextProps.onCreate &&
        prevProps.onCancel === nextProps.onCancel &&
        prevProps.creatorType === nextProps.creatorType &&
        prevProps.labelNames.length === nextProps.labelNames.length &&
        prevProps.labelNames.every((value: string, index: number) => nextProps.labelNames[index] === value) &&
        prevProps.labels.length === nextProps.labels.length &&
        prevProps.labels.every((label: LabelOptColor, index: number) => nextProps.labels[index].id === label.id)
    );
}

function ConstructorCreator(props: Props): JSX.Element {
    const {
        onCreate, onCancel, onDelete, onUpdate, labelNames, creatorType, labels, fullOptionsAsProps,
    } = props;
    const skeletonConfiguratorRef = useRef<SkeletonConfigurator>(null);
    const labelListRef = useRef(null);

    const onSkeletonSubmit = useCallback((): SkeletonConfiguration | null => {
        if (skeletonConfiguratorRef.current) {
            return skeletonConfiguratorRef.current.submit();
        }

        return null;
    }, [skeletonConfiguratorRef]);

    const resetSkeleton = useCallback((): void => {
        if (skeletonConfiguratorRef.current) {
            skeletonConfiguratorRef.current.reset();
        }
    }, [skeletonConfiguratorRef]);

    // whenever 'labels' changes, auto rerender this component with new labels
    useEffect(() => {
        if (labelListRef.current) {
            const labelListRefCurrent = labelListRef.current as HTMLElement;
            labelListRefCurrent.scrollTop = labelListRefCurrent.scrollHeight;
        }
    }, [labels]);

    return (
        <>
            <div className='cvat-label-constructor-creator'>
                <LabelForm
                    fullOptionsAsProps={fullOptionsAsProps}
                    label={null}
                    labelNames={labelNames}
                    onSubmit={onCreate}
                    onSkeletonSubmit={creatorType === 'skeleton' ? onSkeletonSubmit : undefined}
                    resetSkeleton={creatorType === 'skeleton' ? resetSkeleton : undefined}
                    onCancel={onCancel}
                />
                {
                    creatorType === 'skeleton' && (
                        <SkeletonConfigurator label={null} ref={skeletonConfiguratorRef} />
                    )
                }
            </div>
            <div className='to-create-labels-container'>
                {labels.length > 0 ? (
                    <>
                        <Text className='cvat-text-color'>Label list:</Text>
                    </>
                ) : null}
                <div className='to-create-labels-list' ref={labelListRef}>
                    {labels.map((label: any): JSX.Element => (
                        <ConstructorCreatorViewerItem
                            label={label}
                            key={label.id}
                            color={label.color}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}

export default React.memo(ConstructorCreator, compareProps);
