// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Row, Col } from 'antd/lib/grid';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import { deleteDatasetAsync, updateProjectAsync, uploadDatasetsAsync } from 'actions/projects-actions';
import { Project } from 'cvat-core-wrapper';
import UserSelector from 'components/task-page/user-selector';
import {
    Button, Card, Modal, Tabs, Tooltip,
} from 'antd';
import {
    DeleteOutlined,
    ExclamationCircleFilled,
    PlusCircleOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { ImageIcon } from 'icons';
import UploadDatasetsForm from './upload-dataset-form-rainscales';

interface DetailsComponentProps {
    project: Project;
    // onUpdateProject: (project: Project) => void;
    projectDatasetPreviews: { [index: number]: Blob };
}

export interface Datasets {
    localDatasets: { [index: string]: File[] };
    shareDatasets: string[]; // TODO: Implement in the future
    remoteDatasets: string[]; // TODO: Implement in the future
    cloudStorageDatasets: string[]; // TODO: Implement in the future
}

interface LocalDatasetProperty {
    id: number;
    name: string;
    isBeingUsed: boolean;
    relatedTaskNames: string[];
    preview: Blob;
    previewType: string;
}

export default function DetailsComponent(props: DetailsComponentProps): JSX.Element {
    // const { project, onUpdateProject, projectDatasetPreviews } = props;
    const { project, projectDatasetPreviews } = props;

    const dispatch = useDispatch();
    const [projectName, setProjectName] = useState<string>(project.name);

    // States relating to dataset progress display
    const [statusInProgressDatasets, setStatusInProgressDatasets] = useState<string>('');

    // States relating to dataset uploading
    const [isUploadDatasetModalOpen, setIsUploadDatasetModalOpen] = useState(false);
    const [datasets, setDatasets] = useState<Datasets>({
        localDatasets: {},
        shareDatasets: [],
        remoteDatasets: [],
        cloudStorageDatasets: [],
    });
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    // Functions relating to dataset uploading
    const showUploadDatasetModal = (): void => {
        setIsUploadDatasetModalOpen(true);
    };

    const updateStatusInProgress = (status: string): void => {
        setStatusInProgressDatasets(status);
    };

    const resetUploadDatasetModal = (): void => {
        const emptyDatasets: Datasets = {
            localDatasets: {},
            shareDatasets: [],
            remoteDatasets: [],
            cloudStorageDatasets: [],
        };
        setDatasets(emptyDatasets);
        setStatusInProgressDatasets('');
    };

    const submit = async (): Promise<void> => {
        setLoading(true);

        await dispatch(uploadDatasetsAsync(project, datasets, updateStatusInProgress, setLoading));
    };

    const cancelUploadDatasetModal = (): void => {
        setIsUploadDatasetModalOpen(false);
        resetUploadDatasetModal();
    };

    const { confirm } = Modal;
    const showDeleteDatasetConfirm = (id: number): void => {
        confirm({
            title: `Do you want to delete the dataset #${id}?`,
            icon: <ExclamationCircleFilled />,
            async onOk() {
                await dispatch(deleteDatasetAsync(project, id));
            },
        });
    };
    const showDeleteDatasetDeny = (relatedTaskNames: string[]): void => {
        confirm({
            title: `You can not delete this dataset. It is being used in tasks {${relatedTaskNames}}.
                Please remove all the related tasks first.`,
            icon: <ExclamationCircleFilled />,
        });
    };

    const handleDatasetInfoClick = (): void => {
        Modal.info({
            title: 'Support dataset format',
            width: 500,
            content: (
                <>
                    <Tabs tabBarStyle={{ margin: 5 }} defaultActiveKey='1'>
                        <Tabs.TabPane tab='For 2D' key='1'>
                            <Paragraph>Please upload each dataset based on the following format.</Paragraph>
                            <Paragraph>A dataset can only contain:</Paragraph>
                            <Paragraph>a. Multiple images</Paragraph>
                            <Paragraph>b. One video</Paragraph>
                            <Paragraph>c. One pdf</Paragraph>
                            <Paragraph>d. Point cloud data</Paragraph>
                            <Paragraph>e. One zip with either of the above</Paragraph>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab='For 3D' key='2'>
                            <Tabs defaultActiveKey='1'>
                                <Tabs.TabPane tab='Velodyne' key='1'>
                                    <pre>
                                        {`VELODYNE FORMAT
    Structure:
        velodyne_points/
            data/
            image_01.bin
            IMAGE_00 # unknown dirname,
                    # generally image_01.png can be under IMAGE_00, IMAGE_01, IMAGE_02, IMAGE_03, etc
        data/
            image_01.png`}
                                    </pre>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab='3D pointcloud' key='2'>
                                    <pre>
                                        {`3D POINTCLOUD DATA FORMAT
    Structure:
      pointcloud/
        00001.pcd
      related_images/
        00001_pcd/
          image_01.png # or any other image`}
                                    </pre>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab='3D Option 1' key='3'>
                                    <pre>
                                        {`3D, DEFAULT DATAFORMAT Option 1
    Structure:
      data/
        image.pcd
        image.png`}
                                    </pre>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab='3D Option 2' key='4'>
                                    <pre>
                                        {`3D, DEFAULT DATAFORMAT Option 2
    Structure:
      data/
        image_1/
            image_1.pcd
            context_1.png # or any other name
            context_2.jpg`}
                                    </pre>
                                </Tabs.TabPane>
                            </Tabs>
                        </Tabs.TabPane>
                    </Tabs>
                    <Text style={{ color: 'red' }}>*Notes: You can not mix 2D and 3D data in the same dataset.</Text>
                </>
            ),
        });
    };

    const buildUploadDatasetModalTitle = (): JSX.Element => (
        <>
            <Text>Upload datasets</Text>
            <Button type='text' shape='circle' icon={<InfoCircleOutlined />} onClick={handleDatasetInfoClick} />
        </>
    );

    const localDatasets: LocalDatasetProperty[] = [];
    if (project) {
        for (let i = 0; i < project.localDatasets.length; i++) {
            const {
                id, name,
                isBeingUsed, relatedTaskNames,
            } = project.localDatasets[i];

            if (projectDatasetPreviews[id]) {
                const preview = projectDatasetPreviews[id];
                const { type } = projectDatasetPreviews[id];

                localDatasets.push({
                    id,
                    name,
                    isBeingUsed,
                    relatedTaskNames,
                    preview,
                    previewType: type,
                });
            }
        }
    }

    useEffect(() => {
        // only allow datasets to be submitted with at least one dataset added
        setDisableSubmit(Object.keys(datasets.localDatasets).length === 0);
    });

    return (
        <div data-cvat-project-id={project.id} className='cvat-project-details'>
            <Row justify='space-between' className='cvat-project-description'>
                <Col>
                    <Title
                        level={4}
                        editable={{
                            onChange: (value: string): void => {
                                setProjectName(value);
                                project.name = value;
                                // onUpdateProject(project);
                                dispatch(updateProjectAsync(project));
                            },
                        }}
                        className='cvat-text-color cvat-project-name'
                    >
                        {projectName}
                    </Title>
                    <Text type='secondary'>
                        {`Project #${project.id} created`}
                        {project.owner ? ` by ${project.owner.username}` : null}
                        {` on ${moment(project.createdDate).format('MMMM Do YYYY')}`}
                    </Text>
                </Col>
                <Col>
                    <Text type='secondary'>Assigned to </Text>
                    <UserSelector
                        value={project.assignee}
                        onSelect={(user) => {
                            project.assignee = user;
                            // onUpdateProject(project);
                            dispatch(updateProjectAsync(project));
                        }}
                        isJobFilter={false}
                    />
                </Col>
            </Row>
            <Text strong className='cvat-text-color'>
                Datasets
            </Text>
            <div className='rainsales-project-datasets-wrapper'>
                <div className='cvat-project-datasets-section'>
                    <Button className='cvat-project-add-dataset-button' onClick={showUploadDatasetModal}>+</Button>
                    <Modal
                        title={buildUploadDatasetModalTitle()}
                        visible={isUploadDatasetModalOpen}
                        // onOk={submit}
                        onCancel={cancelUploadDatasetModal}
                        maskClosable={false}
                        footer={[
                            <Button key='back' onClick={cancelUploadDatasetModal} loading={loading}>
                                Cancel
                            </Button>,
                            <Button key='submit' type='primary' onClick={submit} disabled={disableSubmit} loading={loading}>
                                Submit
                            </Button>,
                        ]}
                    >
                        <UploadDatasetsForm
                            datasets={datasets}
                            setDatasets={setDatasets}
                            loading={loading}
                        />
                        {statusInProgressDatasets && <Text strong>{statusInProgressDatasets}</Text>}
                    </Modal>
                </div>
                <ScrollMenu>
                    {localDatasets.map((dataset: LocalDatasetProperty) => (
                        <div className='cvat-project-datasets-section' key={dataset.id}>
                            <Card
                                hoverable
                                className='cvat-project-dataset-card'
                                key={dataset.id}
                                cover={dataset.previewType === 'image/jpeg' ?
                                    (
                                        <div className='cvat-project-dataset-card-cover'>
                                            <img
                                                className='cvat-project-dataset-card-cover-image'
                                                alt={dataset.name}
                                                src={URL.createObjectURL(dataset.preview)}
                                            />
                                        </div>
                                    ) : (
                                        <div aria-hidden>
                                            <ImageIcon />
                                        </div>
                                    )}
                                actions={[
                                    <PlusCircleOutlined disabled key='create-task' onClick={() => history.push(`/tasks/create?projectId=${project.id}&datasetId=${dataset.id}`)} />,
                                    <DeleteOutlined key='delete' onClick={() => (dataset.isBeingUsed ? showDeleteDatasetDeny(dataset.relatedTaskNames) : showDeleteDatasetConfirm(dataset.id))} />,
                                ]}
                            >
                                <Tooltip title={dataset.name}>
                                    <Meta
                                        title={dataset.name}
                                    />
                                </Tooltip>
                            </Card>
                        </div>
                    ))}
                </ScrollMenu>
            </div>
        </div>
    );
}
