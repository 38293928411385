// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
// import React from 'react';
import Popover, { PopoverProps } from 'antd/lib/popover';
import Draggable from 'react-draggable';

interface OwnProps {
    overlayClassName?: string;
    onVisibleChange?: (visible: boolean) => void;
}

export default function withVisibilityHandling(WrappedComponent: typeof Popover, popoverType: string) {
    return (props: OwnProps & PopoverProps): JSX.Element => {
        const [visible, setVisible] = useState<boolean>(false);
        const {
            overlayClassName, onVisibleChange, content, children, ...rest
        } = props;
        const overlayClassNames = typeof overlayClassName === 'string' ? overlayClassName.split(/\s+/) : [];
        const popoverClassName = `cvat-${popoverType}-popover`;
        overlayClassNames.push(popoverClassName);

        const { overlayStyle } = props;

        const handleVisibleChange = (_visible: boolean): void => {
            if (_visible) {
                const [element] = window.document.getElementsByClassName(popoverClassName);
                if (element) {
                    element.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }));
                    (element as HTMLElement).style.pointerEvents = '';
                    (element as HTMLElement).style.opacity = '';
                }
            }
            setVisible(_visible);
            if (onVisibleChange) onVisibleChange(_visible);
        };

        // const toggleVisible = (): void => {
        //     setVisible(!visible);
        // };

        // const hidePopup = (): void => {
        //     setVisible(false);
        // };

        // for use case when user click on button outside, highlight the button
        // const blurBackgroundStyle = visible ? {
        //     style: { background: 'rgba(0,0,0,0.2)' },
        // } : {};

        return (
            <WrappedComponent
                {...rest}
                overlayStyle={{
                    ...(typeof overlayStyle === 'object' ? overlayStyle : {}),
                    animationDuration: '0s',
                    animationDelay: '0s',
                }}
                // trigger={visible ? 'click' : 'hover'}
                trigger='click'
                visible={visible}
                overlayClassName={overlayClassNames.join(' ').trim()}
                onVisibleChange={handleVisibleChange}
                content={(
                    // <Draggable>
                    //     <div style={{ position: 'absolute' }}>
                    //         {content}
                    //         <button
                    //             type='button'
                    //             onClick={hidePopup}
                    //             onKeyDown={hidePopup}
                    //             style={{ position: 'absolute', top: '5px', right: '5px' }}
                    //         >
                    //             Close
                    //         </button>
                    //     </div>
                    // </Draggable>
                    <Draggable>
                        <div style={{ position: 'absolute' }}>
                            {content}
                        </div>
                    </Draggable>
                )}
            >
                {children}
                {/* { React.cloneElement(children as React.ReactElement,
                    {
                        // onClick: handleVisibleChange,
                        // onClick: toggleVisible,
                        ...blurBackgroundStyle,
                    })} */}
            </WrappedComponent>
        );
    };
}
