// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Select from 'antd/lib/select';
// import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import notification from 'antd/lib/notification';
import Text from 'antd/lib/typography/Text';
import Alert from 'antd/lib/alert';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import {
    StorageLocation, AIModelType, Project, Task,
} from 'reducers';
import { getCore, Storage } from 'cvat-core-wrapper';
import LabelsEditor from 'components/labels-editor/labels-editor';

import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import { ImageIcon } from 'icons';
import BasicConfigurationForm, { BaseConfiguration } from './basic-configuration-form';
import ProjectSearchField from './project-search-field';
// reserve for future development
// import ProjectSubsetField from './project-subset-field';
// import MultiTasksProgress from './multi-task-progress';
import AdvancedConfigurationForm, { AdvancedConfiguration, SimplifiedAdvancedConfiguration, SortingMethod } from './advanced-configuration-form';

type TabName = 'local' | 'share' | 'remote' | 'cloudStorage';
const core = getCore();

// a constant to defiine universal 'ALL' model group to list all model types (default group type)
const UNIVERSAL_GROUP_NAME = 'ALL';

export interface CreateTaskData {
    projectId: number | null;
    basic: BaseConfiguration;
    subset: string;
    advanced: AdvancedConfiguration;
    labels: any[];
    activeFileManagerTab: TabName;
    cloudStorageId: number | null;
    selectedDataset: {
        initialized: boolean,
        id: number | null
    };
    aiModelSelect: {
        modelGroupOptionsList: string[];
        modelTypeOptionsList: AIModelType[];
        selectedAiLabelsList: string[]; // ai labels corresponding to selected ai model type
        isEnableModelSelect: boolean;
        modelTypeSelectDisabled: boolean;
        currentModelGroupSelect: string; // model group name
        currentModelTypeSelect: string; // model type name
        currentModelTypeIdSelect: number | null; // model type id corresponding to model type
    };
}

interface Props {
    onCreate: (data: CreateTaskData, onProgress?: (status: string, progress?: number) => void) => Promise<any>;
    project: Project;
    datasetId: number | null;
    projectDatasetPreviews: {
        [index: number]: Blob;
    };
    installedGit: boolean;
    dumpers:[];
    many: boolean;
    modelGroupsWithTypes: { [key: string]: AIModelType[] };
    originalTask: Task | null;
}

type State = CreateTaskData & {
    multiTasks: (CreateTaskData & {
        status: 'pending' | 'progress' | 'failed' | 'completed' | 'cancelled';
    })[];
    uploadFileErrorMessage: string;
    loading: boolean;
    statusInProgressTask: string;
    labelsInitialized: boolean;
    aiModelSelectInitialzed: boolean;
};

const defaultState: State = {
    projectId: null,
    basic: {
        name: '',
    },
    subset: '',
    advanced: {
        lfs: false,
        useZipChunks: true,
        useCache: true,
        sortingMethod: SortingMethod.LEXICOGRAPHICAL,
        sourceStorage: {
            location: StorageLocation.LOCAL,
            cloudStorageId: undefined,
        },
        targetStorage: {
            location: StorageLocation.LOCAL,
            cloudStorageId: undefined,
        },
        useProjectSourceStorage: true,
        useProjectTargetStorage: true,
    },
    labels: [],
    labelsInitialized: false,
    activeFileManagerTab: 'local',
    cloudStorageId: null,
    multiTasks: [],
    uploadFileErrorMessage: '',
    loading: false,
    statusInProgressTask: '',
    selectedDataset: {
        id: null,
        initialized: false,
    },
    aiModelSelect: {
        modelGroupOptionsList: [],
        modelTypeOptionsList: [],
        selectedAiLabelsList: [],
        isEnableModelSelect: false,
        // modelTypeSelectDisabled: true,
        modelTypeSelectDisabled: false, // always enable model type select
        currentModelGroupSelect: '',
        currentModelTypeSelect: '',
        currentModelTypeIdSelect: null,
    },
    aiModelSelectInitialzed: false,
};

class CreateTaskContent extends React.PureComponent<Props & RouteComponentProps, State> {
    private basicConfigurationComponent: RefObject<BasicConfigurationForm>;
    private advancedConfigurationComponent: RefObject<AdvancedConfigurationForm>;
    private fileManagerContainer: any;

    public constructor(props: Props & RouteComponentProps) {
        super(props);
        this.state = { ...defaultState };
        this.basicConfigurationComponent = React.createRef<BasicConfigurationForm>();
        this.advancedConfigurationComponent = React.createRef<AdvancedConfigurationForm>();
    }

    public componentDidMount(): void {
        const { modelGroupsWithTypes } = this.props;

        this.focusToForm();

        // build up list of model group names based on Dictionary data
        const modelGroupOptionsList: string[] = [];
        Object.keys(modelGroupsWithTypes).forEach((keyName) => {
            modelGroupOptionsList.push(keyName);
        });
        modelGroupOptionsList.push(UNIVERSAL_GROUP_NAME);

        this.setState((state) => ({
            ...state,
            aiModelSelect: {
                ...state.aiModelSelect,
                modelGroupOptionsList: modelGroupOptionsList.sort(),
            },
        }));

        // set default model types options list
        this.buildModelTypeOptionsList(UNIVERSAL_GROUP_NAME);
    }

    public componentDidUpdate(): void {
        const {
            project, datasetId, originalTask, modelGroupsWithTypes,
        } = this.props;
        const { selectedDataset: { initialized }, labelsInitialized, aiModelSelectInitialzed } = this.state;

        if (project !== undefined) {
            this.setState((state) => ({
                ...state,
                projectId: project.id,
            }));

            const { localDatasets } = project;

            let selectedDatasetId: number | null = null;

            if (originalTask) {
                selectedDatasetId = localDatasets.find((ds: any) => ds.name === originalTask.selectedDataset).id;
            } else if (datasetId) {
                selectedDatasetId = localDatasets.find((ds: any) => ds.id === datasetId).id;
            }

            if (selectedDatasetId && !initialized) {
                this.setState((state) => ({
                    ...state,
                    selectedDataset: {
                        initialized: true,
                        id: selectedDatasetId,
                    },
                }));
            }
        }

        if (originalTask) {
            const copyLabels = originalTask.labels.map((label: any) => {
                delete label.id;

                label.attributes.map((attr: any) => {
                    attr.input_type = attr.inputType;
                    attr.default_value = attr.defaultValue;
                    attr.is_being_used = false;
                    delete attr.id;
                    return attr;
                });

                if (label.type === 'skeleton') {
                    label.svg = label.structure?.svg;
                    label.sublabels = label.structure?.sublabels;
                    label.sublabels?.map((sublabel: any) => {
                        delete sublabel.id;

                        sublabel.attributes.map((attr: any) => {
                            attr.input_type = attr.inputType;
                            attr.default_value = attr.defaultValue;
                            attr.is_being_used = false;
                            delete attr.id;
                            return attr;
                        });

                        return sublabel;
                    });
                }

                return label;
            });

            if (copyLabels?.length && !labelsInitialized) {
                this.setState((state) => ({
                    ...state,
                    labels: copyLabels,
                    labelsInitialized: true,
                }));
            }

            if (originalTask.modelTypeId && !aiModelSelectInitialzed) {
                let copiedModelGroup = '';
                let copiedModelType: any = null;
                for (const modelGroup of Object.keys(modelGroupsWithTypes)) {
                    for (const modelType of modelGroupsWithTypes[modelGroup]) {
                        if (modelType.id === originalTask.modelTypeId) {
                            copiedModelGroup = modelGroup;
                            copiedModelType = modelType;
                            break;
                        }
                    }
                    if (copiedModelGroup.length && copiedModelType) break;
                }

                if (copiedModelGroup.length && copiedModelType) {
                    this.setState((state) => ({
                        ...state,
                        aiModelSelect: {
                            ...state.aiModelSelect,
                            isEnableModelSelect: true,
                            currentModelGroupSelect: copiedModelGroup,
                            currentModelTypeIdSelect: copiedModelType.id,
                            currentModelTypeSelect: copiedModelType.name,
                        },
                        aiModelSelectInitialzed: true,
                    }));

                    core.aiModelTypes.get({ id: copiedModelType.id })
                        .then((response: any) => {
                            const labelNames: string[] = response[0].label_names;
                            this.updateSelectedAiLabelsList(labelNames);
                        });
                }
            }
        }
    }

    private handleChangeStorageLocation(field: 'sourceStorage' | 'targetStorage', value: StorageLocation): void {
        this.setState((state) => ({
            advanced: {
                ...state.advanced,
                [field]: {
                    location: value,
                },
            },
        }));
    }

    private resetState = (): void => {
        this.basicConfigurationComponent.current?.resetFields();
        this.advancedConfigurationComponent.current?.resetFields();

        this.fileManagerContainer.reset();

        this.setState((state) => ({
            ...defaultState,
            projectId: state.projectId,
        }));
    };

    private validateLabels = (): boolean => {
        const { labels } = this.state;
        return !!labels.length;
    };

    private validateFiles = (): boolean => {
        const { selectedDataset: { id } } = this.state;
        return !!id;
    };

    private startLoading = (): void => {
        this.setState({
            loading: true,
        });
    };

    private stopLoading = (): void => {
        this.setState({
            loading: false,
        });
    };

    private changeStatusInProgressTask = (status: string): void => {
        this.setState({
            statusInProgressTask: status,
        });
    };

    private handleProjectIdChange = (value: null | number): void => {
        const { projectId, subset } = this.state;

        this.setState((state) => ({
            ...state,
            projectId: value,
            subset: value && value === projectId ? subset : '',
        }));
    };

    private handleChangeBasicConfiguration = (values: BaseConfiguration): void => {
        this.setState({
            basic: { ...values },
        });
    };

    private handleSubmitAdvancedConfiguration = (values: AdvancedConfiguration): void => {
        this.setState({
            advanced: { ...values },
        });
    };

    private handleTaskSubsetChange = (value: string): void => {
        this.setState({
            subset: value,
        });
    };

    private changeFileManagerTab = (value: TabName): void => {
        this.setState({
            activeFileManagerTab: value,
        });
    };

    private handleUseProjectSourceStorageChange = (value: boolean): void => {
        this.setState((state) => ({
            advanced: {
                ...state.advanced,
                useProjectSourceStorage: value,
            },
        }));
    };

    private handleUseProjectTargetStorageChange = (value: boolean): void => {
        this.setState((state) => ({
            advanced: {
                ...state.advanced,
                useProjectTargetStorage: value,
            },
        }));
    };

    private updateAIModelSelect = (value: boolean): void => {
        this.setState((state) => ({
            ...state,
            aiModelSelect: {
                ...state.aiModelSelect,
                isEnableModelSelect: value,
            },
        }));
    };

    // private updateModelTypeSelectDisabled = (value: boolean): void => {
    //     this.setState((state) => ({
    //         ...state,
    //         aiModelSelect: {
    //             ...state.aiModelSelect,
    //             modelTypeSelectDisabled: value,
    //         },
    //     }));
    // };

    private updateCurrentModelGroupSelect = (value: string): void => {
        this.setState((state) => ({
            ...state,
            aiModelSelect: {
                ...state.aiModelSelect,
                currentModelGroupSelect: value,
            },
        }));
    };

    private updateCurrentModelTypeSelect = (value: string): void => {
        this.setState((state) => ({
            ...state,
            aiModelSelect: {
                ...state.aiModelSelect,
                currentModelTypeSelect: value,
            },
        }));
    };

    private updateCurrentModelTypeIdSelect = (value: number | null): void => {
        this.setState((state) => ({
            ...state,
            aiModelSelect: {
                ...state.aiModelSelect,
                currentModelTypeIdSelect: value,
            },
        }));
    };

    private updateSelectedAiLabelsList = (value: string[]): void => {
        this.setState((state) => ({
            ...state,
            aiModelSelect: {
                ...state.aiModelSelect,
                selectedAiLabelsList: value,
            },
        }));
    };

    private buildModelTypeOptionsList = (groupName: string): void => {
        const { modelGroupsWithTypes } = this.props;

        const nameSortCallback = (a: AIModelType, b: AIModelType) : number => {
            if (a.name > b.name) {
                return 1;
            }

            if (a.name < b.name) {
                return -1;
            }

            return 0;
        };

        if (groupName === UNIVERSAL_GROUP_NAME) {
            // build up a unique set with all model types
            const allModelTypeOptions: AIModelType[] = [];
            // loop all possible values of type
            Object.values(modelGroupsWithTypes).forEach((optionsArr) => optionsArr.forEach((obj) => {
                // check model type id, if not included, push model type to available options
                // this to make sure all options are UNIQUE objects
                if (!allModelTypeOptions.map((t) => t.id).includes(obj.id)) {
                    allModelTypeOptions.push(obj);
                }
            }));

            this.setState((state) => ({
                ...state,
                aiModelSelect: {
                    ...state.aiModelSelect,
                    modelTypeOptionsList: allModelTypeOptions.sort(nameSortCallback),
                },
            }));
            return;
        }

        const modelTypeOptionsList = modelGroupsWithTypes[groupName];
        if (modelTypeOptionsList !== undefined) {
            this.setState((state) => ({
                ...state,
                aiModelSelect: {
                    ...state.aiModelSelect,
                    modelTypeOptionsList: modelTypeOptionsList.sort(nameSortCallback),
                },
            }));
        }
    }

    private resetModelSelectionState = (): void => {
        this.setState((state) => ({
            ...state,
            aiModelSelect: {
                ...state.aiModelSelect,
                modelTypeOptionsList: [],
                selectedAiLabelsList: [],
                isEnableModelSelect: false,
                // modelTypeSelectDisabled: true,
                currentModelGroupSelect: '',
                currentModelTypeSelect: '',
                currentModelTypeIdSelect: null,
            },
        }));

        this.buildModelTypeOptionsList(UNIVERSAL_GROUP_NAME);
    }

    private focusToForm = (): void => {
        this.basicConfigurationComponent.current?.focus();
    };

    private validateBlocks = (): Promise<any> => new Promise((resolve, reject) => {
        const { projectId } = this.state;
        // reserve for future development
        // if (!this.validateLabels()) {
        //     notification.error({
        //         message: 'Could not create a task',
        //         description: 'A task must contain at least one label.',
        //         className: 'cvat-notification-create-task-fail',
        //     });
        //     reject();
        //     return;
        // }

        if (!this.validateFiles()) {
            notification.error({
                message: 'Could not create a task',
                description: 'A task must contain at least one dataset.',
                className: 'cvat-notification-create-task-fail',
            });
            reject();
            return;
        }

        if (!this.basicConfigurationComponent.current) {
            reject();
            return;
        }

        this.basicConfigurationComponent.current
            .submit()
            .then(() => {
                if (this.advancedConfigurationComponent.current) {
                    return this.advancedConfigurationComponent.current.submit();
                }
                if (projectId) {
                    return core.projects.get({ id: projectId })
                        .then((response: any) => {
                            const [project] = response;
                            const { advanced } = this.state;
                            this.handleSubmitAdvancedConfiguration({
                                ...advanced,
                                sourceStorage: new Storage(
                                    project.sourceStorage || { location: StorageLocation.LOCAL },
                                ),
                                targetStorage: new Storage(
                                    project.targetStorage || { location: StorageLocation.LOCAL },
                                ),
                            });
                            return Promise.resolve();
                        })
                        .catch((error: Error): void => {
                            throw new Error(`Couldn't fetch the project ${projectId} ${error.toString()}`);
                        });
                }
                return Promise.resolve();
            })
            .then(resolve)
            .catch((error: Error | ValidateErrorEntity): void => {
                notification.error({
                    message: 'Could not create a task',
                    description: (error as ValidateErrorEntity).errorFields ?
                        (error as ValidateErrorEntity).errorFields
                            .map((field) => `${field.name} : ${field.errors.join(';')}`)
                            .map((text: string): JSX.Element => <div>{text}</div>) :
                        error.toString(),
                    className: 'cvat-notification-create-task-fail',
                });
                reject(error);
            });
    });

    private handleSubmitAndOpen = (): void => {
        const { history } = this.props;

        this.validateBlocks()
            .then(this.createOneTask)
            .then((createdTask) => {
                const { id } = createdTask;
                history.push(`/tasks/${id}`);
            })
            .catch(() => {});
    };

    private handleSubmitAndContinue = (): void => {
        this.validateBlocks()
            .then(this.createOneTask)
            .then(() => {
                notification.info({
                    message: 'The task has been created',
                    className: 'cvat-notification-create-task-success',
                });
            })
            .then(this.resetState)
            .then(this.focusToForm)
            .catch(() => {});
    };

    private createOneTask = (): Promise<any> => {
        const { onCreate } = this.props;
        this.startLoading();
        return onCreate(this.state, this.changeStatusInProgressTask)
            .finally(this.stopLoading);
    };

    // reserve for future development
    // private setStatusOneOfMultiTasks = async (index: number, status: string): Promise<void> => {
    //     const { multiTasks } = this.state;
    //     const resultTask = {
    //         ...multiTasks[index],
    //         status,
    //     };

    //     return new Promise((resolve) => {
    //         const newMultiTasks: any = [
    //             ...multiTasks.slice(0, index),
    //             resultTask,
    //             ...multiTasks.slice(index + 1),
    //         ];
    //         this.setState({
    //             multiTasks: newMultiTasks,
    //         }, resolve);
    //     });
    // };

    // reserve for future development
    // private createOneOfMultiTasks = async (index: any): Promise<void> => {
    //     const { onCreate } = this.props;
    //     const { multiTasks } = this.state;
    //     const task = multiTasks[index];

    //     if (task.status !== 'pending') return;

    //     await this.setStatusOneOfMultiTasks(index, 'progress');
    //     try {
    //         await onCreate(task);
    //         await this.setStatusOneOfMultiTasks(index, 'completed');
    //     } catch (err) {
    //         console.warn(err);
    //         await this.setStatusOneOfMultiTasks(index, 'failed');
    //     }
    // };

    // reserve for future development
    // private createMultiTasks = async (): Promise<any> => {
    //     const { multiTasks } = this.state;
    //     this.startLoading();
    //     const { length } = multiTasks;
    //     let index = 0;
    //     const queueSize = 1;
    //     const promises = Array(queueSize)
    //         .fill(undefined)
    //         .map(async (): Promise<void> => {
    //             // eslint-disable-next-line no-constant-condition
    //             while (true) {
    //                 index++; // preliminary increase is needed to avoid using the same index when queueSize > 1
    //                 if (index > length) break;
    //                 await this.createOneOfMultiTasks(index - 1);
    //             }
    //         });
    //     await Promise.allSettled(promises);
    //     this.stopLoading();
    // };

    // reserve for future development
    // private addMultiTasks = async (): Promise<void> => new Promise((resolve) => {
    //     const {
    //         projectId,
    //         subset,
    //         advanced,
    //         labels,
    //         files: allFiles,
    //         activeFileManagerTab,
    //         cloudStorageId,
    //     } = this.state;

    //     const files: (File | string)[] = allFiles[activeFileManagerTab];

    //     this.setState({
    //         multiTasks: files.map((file, index) => ({
    //             projectId,
    //             basic: {
    //                 name: this.getTaskName(index, activeFileManagerTab),
    //             },
    //             subset,
    //             advanced,
    //             labels,
    //             files: {
    //                 ...defaultState.files,
    //                 [activeFileManagerTab]: [file],
    //             },
    //             activeFileManagerTab,
    //             cloudStorageId,
    //             status: 'pending',
    //         }
    //         )),
    //     }, resolve);
    // });

    // reserve for future development
    // private handleSubmitMultiTasks = (): void => {
    //     this.validateBlocks()
    //         .then(() => {
    //             this.addMultiTasks();
    //         })
    //         .then(this.createMultiTasks)
    //         .then(() => {
    //             const { multiTasks } = this.state;
    //             const countCompleted = multiTasks.filter((item) => item.status === 'completed').length;
    //             const countFailed = multiTasks.filter((item) => item.status === 'failed').length;
    //             const countCancelled = multiTasks.filter((item) => item.status === 'cancelled').length;
    //             const countAll = multiTasks.length;

    //             notification.info({
    //                 message: 'The tasks have been created',
    //                 description:
    //                     `Completed: ${countCompleted}, failed: ${countFailed},${countCancelled ?
    //                         ` cancelled: ${countCancelled},` :
    //                         ''} total: ${countAll}, `,
    //                 className: 'cvat-notification-create-task-success',
    //             });
    //         });
    // };

    // reserve for future development
    // private handleCancelMultiTasks = (): void => {
    //     const { multiTasks } = this.state;
    //     let count = 0;
    //     const newMultiTasks: any = multiTasks.map((it) => {
    //         if (it.status === 'pending') {
    //             count++;
    //             return {
    //                 ...it,
    //                 status: 'cancelled',
    //             };
    //         }
    //         return it;
    //     });
    //     this.setState({
    //         multiTasks: newMultiTasks,
    //     }, () => {
    //         notification.info({
    //             message: `Creation of ${count} tasks have been canceled`,
    //             className: 'cvat-notification-create-task-success',
    //         });
    //     });
    // };

    // reserve for future development
    // private handleOkMultiTasks = (): void => {
    //     const { history, projectId } = this.props;
    //     if (projectId) {
    //         history.push(`/projects/${projectId}`);
    //     } else {
    //         history.push('/tasks/');
    //     }
    // };

    // reserve for future development
    // private handleRetryCancelledMultiTasks = (): void => {
    //     const { multiTasks } = this.state;
    //     const newMultiTasks: any = multiTasks.map((it) => {
    //         if (it.status === 'cancelled') {
    //             return {
    //                 ...it,
    //                 status: 'pending',
    //             };
    //         }
    //         return it;
    //     });
    //     this.setState({
    //         multiTasks: newMultiTasks,
    //     }, () => {
    //         this.createMultiTasks();
    //     });
    // };

    // reserve for future development
    // private handleRetryFailedMultiTasks = (): void => {
    //     const { multiTasks } = this.state;
    //     const newMultiTasks: any = multiTasks.map((it) => {
    //         if (it.status === 'failed') {
    //             return {
    //                 ...it,
    //                 status: 'pending',
    //             };
    //         }
    //         return it;
    //     });
    //     this.setState({
    //         multiTasks: newMultiTasks,
    //     }, () => {
    //         this.createMultiTasks();
    //     });
    // };

    // reserve for future development
    // private getTaskName = (indexFile: number, fileManagerTabName: TabName, defaultFileName = ''): string => {
    //     reserve for future development
    //     const { many } = this.props;
    //     const { files } = this.state;
    //     const file = files[fileManagerTabName][indexFile];
    //     let fileName = defaultFileName;
    //     switch (fileManagerTabName) {
    //         case 'remote':
    //             fileName = getFileNameFromPath(file as string) || defaultFileName;
    //             break;
    //         case 'share':
    //             fileName = getFileNameFromPath(file as string) || defaultFileName;
    //             break;
    //         default:
    //             fileName = (file as File)?.name || (file as string) || defaultFileName;
    //             break;
    //     }
    //     return many ?
    //         basic.name
    //             .replaceAll('{{file_name}}', fileName)
    //             .replaceAll('{{index}}', indexFile.toString()) :
    //         basic.name;
    // };

    private renderBasicBlock(): JSX.Element {
        const { many } = this.props;
        // reserve for future development
        // const exampleMultiTaskName = many ? this.getTaskName(0, 'local', 'fileName.mp4') : '';

        return (
            <Col span={24}>
                <BasicConfigurationForm
                    ref={this.basicConfigurationComponent}
                    many={many}
                    // exampleMultiTaskName={exampleMultiTaskName}
                    onChange={this.handleChangeBasicConfiguration}
                />
            </Col>
        );
    }

    private renderProjectBlock(): JSX.Element {
        const { projectId } = this.state;

        return (
            <>
                <Col span={24}>
                    <Text className='cvat-title'>I. Project</Text>
                </Col>
                <Col span={24}>
                    <ProjectSearchField onSelect={this.handleProjectIdChange} value={projectId} />
                </Col>
            </>
        );
    }

    // reserve for future development
    // private renderSubsetBlock(): JSX.Element | null {
    //     const { projectId, subset } = this.state;

    //     if (projectId !== null) {
    //         return (
    //             <>
    //                 <Col span={24}>
    //                     <Text className='cvat-text-color'>Subset</Text>
    //                 </Col>
    //                 <Col span={24}>
    //                     <ProjectSubsetField
    //                         value={subset}
    //                         onChange={this.handleTaskSubsetChange}
    //                         projectId={projectId}
    //                     />
    //                 </Col>
    //             </>
    //         );
    //     }

    //     return null;
    // }

    private renderLabelsBlock(): JSX.Element {
        const { labels, aiModelSelect: { selectedAiLabelsList } } = this.state;

        return (
            <Col span={24}>
                {/* <Text type='danger'>* </Text> */}
                <Text className='cvat-text-color' strong>Labels</Text>
                <LabelsEditor
                    fullOptionsAsProps={selectedAiLabelsList}
                    labels={labels}
                    onSubmit={(newLabels): void => {
                        this.setState({
                            labels: newLabels,
                        });
                    }}
                />
            </Col>
        );
    }

    private renderDatasetsBlock(): JSX.Element {
        const { selectedDataset } = this.state;
        const { project, projectDatasetPreviews } = this.props;

        const renderLocalDatasets = [];
        if (project && Object.keys(projectDatasetPreviews).length) {
            const projectLocalDatasets = project.localDatasets;
            for (let i = 0; i < projectLocalDatasets.length; i++) {
                const { id, name } = projectLocalDatasets[i];
                const { type } = projectDatasetPreviews[id];
                renderLocalDatasets.push({
                    id,
                    name,
                    preview: projectDatasetPreviews[id],
                    previewType: type,
                });
            }
        }

        return (
            <div style={{ maxWidth: '658px' }}>
                <Text type='danger'>* </Text>
                <Text className='cvat-text-color' strong>Dataset</Text>
                <ScrollMenu>
                    {renderLocalDatasets.map((localDataset: any) => (
                        <div className='cvat-project-datasets-section' key={localDataset.id}>
                            <Card
                                hoverable
                                className='cvat-project-dataset-card'
                                key={localDataset.id}
                                cover={localDataset.previewType === 'image/jpeg' ?
                                    (
                                        <div className='cvat-project-dataset-card-cover'>
                                            <img
                                                className='cvat-project-dataset-card-cover-image'
                                                alt='test'
                                                src={URL.createObjectURL(localDataset.preview)}
                                            />
                                        </div>
                                    ) : (
                                        <div aria-hidden>
                                            <ImageIcon />
                                        </div>
                                    )}
                                actions={[
                                    localDataset.id === selectedDataset.id ? (
                                        <CheckCircleFilled
                                            style={{ fontSize: '20px', color: '#1990ff' }}
                                            key='select-dataset'
                                        />
                                    ) : (
                                        <CheckCircleOutlined
                                            style={{ fontSize: '20px' }}
                                            key='select-dataset'
                                            onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    selectedDataset: {
                                                        ...selectedDataset,
                                                        id: localDataset.id,
                                                    },
                                                }));
                                            }}
                                        />
                                    ),
                                ]}
                            >
                                <Meta
                                    title={localDataset.name}
                                />
                            </Card>
                        </div>
                    ))}
                </ScrollMenu>
            </div>
        );
    }

    private renderAIModelSelectionBlock(): JSX.Element {
        const { aiModelSelect } = this.state;
        const {
            isEnableModelSelect, modelGroupOptionsList, modelTypeOptionsList,
            modelTypeSelectDisabled, currentModelTypeSelect, currentModelGroupSelect,
        } = aiModelSelect;

        const onCheckboxChange = (): void => {
            if (isEnableModelSelect) {
                // if model select is currently enabled (meaning user has hitten button to DISABLE)
                // we reset all the state of ai models
                this.resetModelSelectionState();
            } else { // isEnableModelSelect = false
                this.updateAIModelSelect(true);
            }
        };

        const onSelectModelGroupChange = (value: string): void => {
            // enable the model type selection when model group is chosen
            // this.updateModelTypeSelectDisabled(false);

            // clear value of model type when model group changes
            this.updateCurrentModelTypeSelect('');
            this.updateSelectedAiLabelsList([]); // clear out array
            this.updateCurrentModelTypeIdSelect(null);

            this.updateCurrentModelGroupSelect(value);
            this.buildModelTypeOptionsList(value);
        };

        const onSelectModelTypeChange = (modelTypeName: string): void => {
            // search the model type name with the preloaded model types list
            const selectedModelType : AIModelType[] =
                modelTypeOptionsList.filter((modelType) => modelType.name === modelTypeName);

            const selectedModelTypeId = selectedModelType[0].id;

            // load labels corresponding to model type id
            core.aiModelTypes.get({ id: selectedModelTypeId })
                .then((response: any) => {
                    /** Sample response: 28 Mar 2023
                        [{
                            "id": 1,
                            "name": "type1",
                            "ai_model_groups": [
                                "group1",
                                "group2",
                                "group3",
                                "group5"
                            ],
                            "label_names": [
                                "face",
                                "eye",
                                "hand"
                            ]
                        }]
                     */
                    // TODO refactor the code to Wrap response[0].label_names inside object like create Task does
                    const labelNames: string[] = response[0].label_names;
                    this.updateSelectedAiLabelsList(labelNames);
                });

            this.updateCurrentModelTypeSelect(modelTypeName);
            this.updateCurrentModelTypeIdSelect(selectedModelTypeId);
        };

        // const onSelectSearch = (value: string): void => {
        //     console.log('search:', value);
        // };

        return (
            <>
                <Col span={24}>
                    {/* <Text type='danger'>* </Text> */}
                    <Text className='cvat-text-color' strong>Model selection</Text>
                    <Row justify='start' align='middle' className='mt-10'>
                        <Checkbox checked={isEnableModelSelect} disabled={false} onChange={onCheckboxChange}>
                            Enable AI Support
                        </Checkbox>
                    </Row>
                    {isEnableModelSelect &&
                        (
                            <>
                                <Row justify='start' align='middle' className='mt-10'>
                                    <Col span={5}>
                                        <Text className='padding-lr-10'>Filter by:</Text>
                                    </Col>
                                    <Col span={17}>
                                        <Select
                                            style={{ width: '100%' }}
                                            defaultValue={currentModelGroupSelect?.length ?
                                                currentModelGroupSelect : UNIVERSAL_GROUP_NAME}
                                            showSearch
                                            placeholder='Model Group'
                                            onChange={onSelectModelGroupChange}
                                            // onSearch={onSelectSearch}
                                            filterOption={(input, option) => ((option?.value as string) ?? '').toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {modelGroupOptionsList.map(
                                                (value: string) : JSX.Element => (
                                                    <Select.Option key={value} value={value}>
                                                        {value}
                                                    </Select.Option>
                                                ),
                                            )}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row justify='start' align='middle' className='mt-10'>
                                    <Col span={5}>
                                        <Text className='padding-lr-10'>Model Type:</Text>
                                    </Col>
                                    <Col span={17}>
                                        <Select
                                            disabled={modelTypeSelectDisabled}
                                            style={{ width: '100%' }}
                                            value={currentModelTypeSelect}
                                            showSearch
                                            placeholder='Select your model'
                                            onChange={onSelectModelTypeChange}
                                            // onSearch={onSelectSearch}
                                            filterOption={(input, option) => ((option?.value as string) ?? '').toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {modelTypeOptionsList.map(
                                                (modelType: AIModelType): JSX.Element => (
                                                    <Select.Option key={modelType.id} value={modelType.name}>
                                                        {modelType.name}
                                                    </Select.Option>
                                                ),
                                            )}
                                        </Select>
                                    </Col>
                                </Row>
                            </>
                        )}
                </Col>
            </>
        );
    }

    private renderAdvancedBlock(): JSX.Element {
        // reserve for future development
        // const { installedGit, dumpers, originalTask } = this.props;
        // const { activeFileManagerTab, projectId } = this.state;
        // const {
        //     advanced: {
        //         useProjectSourceStorage,
        //         useProjectTargetStorage,
        //         sourceStorage: {
        //             location: sourceStorageLocation,
        //         },
        //         targetStorage: {
        //             location: targetStorageLocation,
        //         },
        //     },
        // } = this.state;
        const { originalTask } = this.props;
        const {
            imageQuality,
            overlap, segmentSize,
            startFrame, stopFrame, frameFilter,
            dataChunkSize,
        } = originalTask ?? {};

        const copyValues: SimplifiedAdvancedConfiguration | null =
            (originalTask && Object.keys(originalTask).length) ? {
                imageQuality,
                overlapSize: overlap,
                segmentSize,
                startFrame,
                stopFrame,
                frameFilter,
                dataChunkSize,
            } : null;

        return (
            <Col span={24}>
                <Collapse className='cvat-advanced-configuration-wrapper'>
                    <Collapse.Panel key='1' header={<Text className='cvat-title'>III. Advanced configuration</Text>}>
                        <AdvancedConfigurationForm
                            // dumpers={dumpers}
                            // installedGit={installedGit}
                            // activeFileManagerTab={activeFileManagerTab}
                            // useProjectSourceStorage={useProjectSourceStorage}
                            // useProjectTargetStorage={useProjectTargetStorage}
                            // sourceStorageLocation={sourceStorageLocation}
                            // targetStorageLocation={targetStorageLocation}
                            // onChangeUseProjectSourceStorage={this.handleUseProjectSourceStorageChange}
                            // onChangeUseProjectTargetStorage={this.handleUseProjectTargetStorageChange}
                            // onChangeSourceStorageLocation={(value: StorageLocation) => {
                            //     this.handleChangeStorageLocation('sourceStorage', value);
                            // }}
                            // onChangeTargetStorageLocation={(value: StorageLocation) => {
                            //     this.handleChangeStorageLocation('targetStorage', value);
                            // }}
                            // projectId={projectId}
                            ref={this.advancedConfigurationComponent}
                            onSubmit={this.handleSubmitAdvancedConfiguration}
                            copyValues={copyValues}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Col>
        );
    }

    private renderFooterSingleTask(): JSX.Element {
        const { uploadFileErrorMessage, loading, statusInProgressTask: status } = this.state;

        if (status === 'FAILED' || loading) {
            return (<Alert message={status} />);
        }
        return (
            <Row justify='end' gutter={5}>
                <Col>
                    <Button
                        className='cvat-submit-open-task-button'
                        type='primary'
                        onClick={this.handleSubmitAndOpen}
                        disabled={!!uploadFileErrorMessage}
                    >
                        Submit & Open
                    </Button>
                </Col>
                <Col>
                    <Button
                        className='cvat-submit-open-task-button'
                        type='primary'
                        onClick={this.handleSubmitAndContinue}
                        disabled={!!uploadFileErrorMessage}
                    >
                        Submit & Continue
                    </Button>
                </Col>
            </Row>
        );
    }

    // reserve for future development
    // private renderFooterMultiTasks(): JSX.Element {
    //     const {
    //         multiTasks: items,
    //         uploadFileErrorMessage,
    //         files,
    //         activeFileManagerTab,
    //         loading,
    //     } = this.state;
    //     const currentFiles = files[activeFileManagerTab];
    //     const countPending = items.filter((item) => item.status === 'pending').length;
    //     const countAll = items.length;

    //     if ((loading || countPending !== countAll) && currentFiles.length) {
    //         return (
    //             <MultiTasksProgress
    //                 tasks={items}
    //                 onOk={this.handleOkMultiTasks}
    //                 onCancel={this.handleCancelMultiTasks}
    //                 onRetryFailedTasks={this.handleRetryFailedMultiTasks}
    //                 onRetryCancelledTasks={this.handleRetryCancelledMultiTasks}
    //             />
    //         );
    //     }

    //     return (
    //         <Row justify='end' gutter={5}>
    //             <Col>
    //                 <Button
    //                     className='cvat-submit-multiple-tasks-button'
    //                     htmlType='submit'
    //                     type='primary'
    //                     onClick={this.handleSubmitMultiTasks}
    //                     disabled={!!uploadFileErrorMessage}
    //                 >
    //                     Submit&nbsp;
    //                     {currentFiles.length}
    //                     &nbsp;tasks
    //                 </Button>
    //             </Col>
    //         </Row>
    //     );
    // }

    public render(): JSX.Element {
        // reserve for future development
        // const { many } = this.props;

        return (
            <Row justify='start' align='middle' className='cvat-create-task-content'>
                {this.renderProjectBlock()}

                <Col span={24}>
                    <Text className='cvat-title'>II. Basic configuration</Text>
                </Col>

                {this.renderBasicBlock()}
                {/* reserve for future development */}
                {/* {this.renderSubsetBlock()} */}
                {this.renderDatasetsBlock()}
                {this.renderAIModelSelectionBlock()}
                {this.renderLabelsBlock()}
                {this.renderAdvancedBlock()}

                <Col span={24} className='cvat-create-task-content-footer'>
                    {/* reserve for future development */}
                    {/* {many ? this.renderFooterMultiTasks() : this.renderFooterSingleTask() } */}
                    {this.renderFooterSingleTask()}
                </Col>
            </Row>
        );
    }
}

export default withRouter(CreateTaskContent);
