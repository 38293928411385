// Copyright (C) 2023 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, {
    useRef, useState, useEffect,
} from 'react';
import { Col, Row } from 'antd/lib/grid';
// import { PlusOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Form, { FormInstance } from 'antd/lib/form';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
// import Tooltip from 'antd/lib/tooltip';
import Input from 'antd/lib/input';
// import { CheckIcon } from 'icons';
import ConnectedFileManager from 'containers/file-manager/file-manager-rainscales';
import { getFileContentType } from 'utils/files';
import { Datasets } from 'components/project-page/details';

interface UploadDatasetProps {
    datasets: Datasets;
    setDatasets: React.Dispatch<React.SetStateAction<Datasets>>;
    loading: boolean;
}

export default function UploadDatasetsForm(props: UploadDatasetProps): JSX.Element {
    const MAX_DATASET_NAME = 40;
    const {
        datasets,
        setDatasets,
        loading,
    } = props;

    type TabName = 'local' | 'share' | 'remote' | 'cloudStorage';
    const UploadFileErrorMessages = {
        one: 'It can not be processed. You can upload an archive with images, a video or multiple images',
        multi: 'It can not be processed. You can upload one or more videos',
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activeFileManagerTab, setActiveFileManagerTab] = useState('local');
    const [datasetName, setDatasetName] = useState('');
    const [localDataset, setLocalDataset] = useState<File[]>([]);
    const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState('');
    // const [disableSubmitDataset, setDisableSubmitDataset] = useState(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let fileManagerContainer: any;
    const datasetNameInputRef = useRef<Input>(null);
    const datasetNameFormRef = useRef<FormInstance>(null);

    const changeFileManagerTab = (value: TabName): void => {
        setActiveFileManagerTab(value);
    };

    const buildSubmitModalInterface = (uploadedFiles: File[]): JSX.Element => (
        <>
            <Text>Dataset is created with the following files:</Text>
            <li className='uploaded-files'>
                {uploadedFiles.map((file, index) => (
                    <ul key={index}>
                        -
                        {' '}
                        {file.name}
                    </ul>
                ))}
            </li>
        </>
    );

    const handleUploadLocalFiles = async (uploadedFiles: File[]): Promise<void> => {
        const many = false;

        let errorMessage = '';

        if (!many && uploadedFiles.length > 1) {
            errorMessage = uploadedFiles.every((it) => (getFileContentType(it) === 'image' || it.name === 'manifest.jsonl')) ? '' : UploadFileErrorMessages.one;
        } else if (many) {
            errorMessage = uploadedFiles.every((it) => getFileContentType(it) === 'video') ? '' : UploadFileErrorMessages.multi;
        }

        setUploadFileErrorMessage(errorMessage);

        if (!errorMessage) {
            if (datasetNameInputRef.current != null) {
                setLocalDataset(uploadedFiles);

                // Prompt user to input dataset name
                if (datasetNameInputRef.current.props.value === undefined ||
                    datasetNameInputRef.current.props.value === '') {
                    datasetNameInputRef.current.focus();
                }
            }
        }
    };

    const resetDatasetForm = (): void => {
        setDatasetName('');
        datasetNameFormRef.current?.resetFields();
        setLocalDataset([]);
        // fileManagerContainer.reset();
    };

    const handleSubmitDataset = async (): Promise<void> => {
        const updatedDatasets = {
            ...datasets,
        };
        updatedDatasets.localDatasets[datasetName] = localDataset;
        setDatasets(updatedDatasets);

        resetDatasetForm();
    };

    const removeDataset = (datasetNameParam: string): void => {
        const updatedDatasets = {
            ...datasets,
        };
        delete updatedDatasets.localDatasets[datasetNameParam];
        setDatasets(updatedDatasets);
    };

    // useEffect(() => {
    //     // Only allow to submit a dataset when the dataset and its name not empty
    //     setDisableSubmitDataset(datasetName === undefined || datasetName === '' || localDataset.length === 0);
    // });

    useEffect(() => {
        if (uploadFileErrorMessage) {
            Modal.error({
                title: 'Invalid dataset',
                content: uploadFileErrorMessage,
            });
        } else if (localDataset.length) {
            Modal.confirm({
                title: 'Confirm dataset',
                content: buildSubmitModalInterface(localDataset),
                className: 'cvat-modal-confirm-delete-task',
                onOk: handleSubmitDataset,
                okButtonProps: {
                    type: 'primary',
                    danger: true,
                },
                okText: 'OK',
            });
        }
    }, [localDataset, uploadFileErrorMessage]);

    return (
        <>
            {Object.keys(datasets.localDatasets).map((name, index): JSX.Element => (
                <Row key={index} justify='center' gutter={8} style={{ marginTop: '10px' }}>
                    <Col span={20}>
                        <Text strong>{name}</Text>
                    </Col>
                    <Col span={4}>
                        <Button
                            type='primary'
                            danger
                            onClick={() => removeDataset(name)}
                            disabled={loading}
                        >
                            Delete
                        </Button>
                    </Col>
                </Row>
            ))}
            <Row justify='center' gutter={8} style={{ marginTop: '10px' }}>
                <Col span={20}>
                    <Form layout='vertical' ref={datasetNameFormRef}>
                        <Form.Item
                            name='dataset-name'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please, specify a name for your dataset!',
                                },
                                {
                                    max: MAX_DATASET_NAME,
                                    message: 'Dataset name too long!',
                                },
                            ]}
                        >
                            <Input
                                ref={datasetNameInputRef}
                                placeholder='Enter your dataset name...'
                                onChange={(e) => {
                                    setDatasetName(e.target.value);
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={4}>
                    <ConnectedFileManager
                        many={false}
                        onChangeActiveKey={changeFileManagerTab}
                        onUploadLocalFiles={handleUploadLocalFiles}
                        ref={(container: any): void => {
                            fileManagerContainer = container;
                        }}
                        uploadButtonDisabled={datasetName === '' || datasetName.length > MAX_DATASET_NAME || loading}
                    />
                </Col>
            </Row>
            {/* <Row justify='start'>
                <Col span={8} />
                <Col span={4}>
                    <Button
                        // id='cvat-create-project-button'
                        className='add-dataset-mini-button'
                        type='primary'
                        // onClick={() => setIsCreateProjectModalOpen(true)}
                        // icon={<PlusOutlined />}
                    >
                        <Tooltip title='Add another dataset'>
                            <PlusOutlined />
                        </Tooltip>
                    </Button>
                </Col>
                <Col span={8} />
            </Row> */}
            {/* <Text type='danger'>* </Text>
            <Text className='cvat-text-color'>Dataset name</Text>
            <Form layout='vertical' ref={datasetNameFormRef}>
                <Form.Item
                    name='dataset-name'
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please, specify a name for your dataset!',
                        },
                    ]}
                >
                    <Input
                        ref={datasetNameInputRef}
                        placeholder='Enter your dataset name...'
                        onChange={(e) => {
                            setDatasetName(e.target.value);
                        }}
                    />
                </Form.Item>
            </Form>
            <ConnectedFileManager
                many={false}
                onChangeActiveKey={changeFileManagerTab}
                onUploadLocalFiles={handleUploadLocalFiles}
                ref={(container: any): void => {
                    fileManagerContainer = container;
                }}
            /> */}
            <br />
            {/* <Row justify='end'>
                <Col>
                    <Button type='primary' onClick={() => handleSubmitDataset()} disabled={disableSubmitDataset}>
                        Submit dataset
                    </Button>
                </Col>
            </Row> */}
            {/* <Row justify='center' align='middle'>
                <ul>
                    {Object.keys(datasets.localDatasets).map((name): JSX.Element => (<li key={name}>{name}</li>))}
                </ul>
            </Row> */}
        </>
    );
}
