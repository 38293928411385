import React from 'react';

export interface ToolBoxState {
    shapeType: string;
}

export interface ToolBoxAction {
    type: ToolBoxActionTypes;
    payload: ToolBoxState;
}

enum ToolBoxActionTypes {
    SIDEBAR_TOOLBOX_CLICK_ACTION = 'TOOLBOX_BUTTON_HIT',
}

export const initialToolboxState: ToolBoxState = {
    shapeType: '',
};

interface ToolBoxContext {
    state: ToolBoxState;
    dispatch: React.Dispatch<ToolBoxAction>;
}

export const VisibilityContext = React.createContext<ToolBoxContext>(undefined as any);

export const hitButtonToolbox = (shapeType: string): ToolBoxAction => ({
    type: ToolBoxActionTypes.SIDEBAR_TOOLBOX_CLICK_ACTION,
    payload: { shapeType },
});

// reducer
export const buttonToolboxReducer = (state: ToolBoxState, action: ToolBoxAction): ToolBoxState => {
    let newState;

    switch (action.type) {
        case ToolBoxActionTypes.SIDEBAR_TOOLBOX_CLICK_ACTION:
            newState = {
                ...state,
                shapeType: action.payload.shapeType,
            };
            break;
        default:
            newState = state;
    }

    return newState;
};
