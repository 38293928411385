// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
// import Popover from 'antd/lib/popover';
import Icon from '@ant-design/icons';

import { Canvas } from 'cvat-canvas-wrapper';
import { EllipseIcon } from 'icons';
import { ShapeType } from 'reducers';

import DrawShapePopoverContainer from 'containers/annotation-page/standard-workspace/controls-side-bar/draw-shape-popover';
// import withVisibilityHandling from './handle-popover-visibility';
import MyCustomPopover from './custom-popover';

export interface Props {
    canvasInstance: Canvas;
    isDrawing: boolean;
    disabled?: boolean;
}

// const CustomPopover = withVisibilityHandling(Popover, 'draw-ellipse');
function DrawEllipseControl(props: Props): JSX.Element {
    const { canvasInstance, isDrawing, disabled } = props;
    // const dynamicPopoverProps = isDrawing ? {
    //     overlayStyle: {
    //         display: 'none',
    //     },
    // } : {};

    const dynamicIconProps = isDrawing ? {
        className: 'cvat-draw-ellipse-control cvat-active-canvas-control',
        onClick: (): void => {
            canvasInstance.draw({ enabled: false });
        },
    } : {
        className: 'cvat-draw-ellipse-control',
    };

    return disabled ? (
        <Icon className='cvat-draw-ellipse-control cvat-disabled-canvas-control' component={EllipseIcon} />
    ) : (
        // <CustomPopover
        //     {...dynamicPopoverProps}
        //     overlayClassName='cvat-draw-shape-popover'
        //     placement='right'
        //     content={<DrawShapePopoverContainer shapeType={ShapeType.ELLIPSE} />}
        // >
        //     <Icon {...dynamicIconProps} component={EllipseIcon} />
        // </CustomPopover>
        <MyCustomPopover
            shapeType={ShapeType.ELLIPSE}
            overlayClassName='cvat-draw-shape-popover'
            content={<DrawShapePopoverContainer shapeType={ShapeType.ELLIPSE} />}
        >
            <Icon {...dynamicIconProps} component={EllipseIcon} />
        </MyCustomPopover>
    );
}

export default React.memo(DrawEllipseControl);
