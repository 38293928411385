// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';

import { TreeNodeNormal } from 'antd/lib/tree/Tree';
import FileManagerComponent, { Files } from 'components/file-manager/file-manager-rainscales';

import { loadShareDataAsync } from 'actions/share-actions';
import { ShareItem, CombinedState, ShareFileInfo } from 'reducers';

interface OwnProps {
    ref: any;
    many: boolean;
    onChangeActiveKey(key: string): void;
    onUploadLocalFiles(files: File[]): void;
    uploadButtonDisabled: boolean;
}

interface StateToProps {
    treeData: (TreeNodeNormal & { mime_type: string })[];
    share: any;
}

interface DispatchToProps {
    getTreeData(key: string): Promise<ShareFileInfo[]>;
}

function mapStateToProps(state: CombinedState): StateToProps {
    function convert(items: ShareItem[], path?: string): (TreeNodeNormal & { mime_type: string })[] {
        return items.map(
            (item): (TreeNodeNormal & { mime_type: string }) => {
                const isLeaf = item.type !== 'DIR';
                const key = `${path}${item.name}${isLeaf ? '' : '/'}`;
                return {
                    key,
                    isLeaf,
                    title: item.name || 'root',
                    mime_type: item.mime_type,
                    children: convert(item.children, key),
                };
            },
        );
    }

    const { root } = state.share;

    return {
        treeData: convert([root], ''),
        share: state.share,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        getTreeData: (key: string):
        Promise<ShareFileInfo[]> => dispatch(loadShareDataAsync(key)),
    };
}

type Props = StateToProps & DispatchToProps & OwnProps;

export class FileManagerContainer extends React.PureComponent<Props> {
    private managerComponentRef: any;

    public constructor(props: Props) {
        super(props);

        this.managerComponentRef = React.createRef();
    }

    public getFiles(): Files {
        return this.managerComponentRef.getFiles();
    }

    public getCloudStorageId(): number | null {
        return this.managerComponentRef.getCloudStorageId();
    }

    public reset(): Files {
        return this.managerComponentRef.reset();
    }

    public render(): JSX.Element {
        const {
            treeData,
            share,
            getTreeData,
            many,
            onChangeActiveKey,
            onUploadLocalFiles,
            uploadButtonDisabled,
        } = this.props;

        return (
            <FileManagerComponent
                treeData={treeData}
                share={share}
                many={many}
                onLoadData={getTreeData}
                onUploadLocalFiles={onUploadLocalFiles}
                onChangeActiveKey={onChangeActiveKey}
                ref={(component): void => {
                    this.managerComponentRef = component;
                }}
                uploadButtonDisabled={uploadButtonDisabled}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FileManagerContainer);
