// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { RefObject } from 'react';

// import Tabs from 'antd/lib/tabs';
import Upload, { RcFile } from 'antd/lib/upload';
import { UploadOutlined } from '@ant-design/icons';
import { TreeNodeNormal } from 'antd/lib/tree/Tree';
import { FormInstance } from 'antd/lib/form';
import Button from 'antd/lib/button';
// eslint-disable-next-line import/no-extraneous-dependencies

export interface Files {
    local: File[];
}

interface State {
    files: Files;
    active: 'local';
}

interface Props {
    treeData: (TreeNodeNormal & { mime_type: string })[];
    share: any;
    many: boolean;
    onLoadData: (key: string) => Promise<any>;
    onChangeActiveKey(key: string): void;
    onUploadLocalFiles(files: File[]): void;
    uploadButtonDisabled: boolean;
}

export class FileManager extends React.PureComponent<Props, State> {
    private cloudStorageTabFormRef: RefObject<FormInstance>;

    public constructor(props: Props) {
        super(props);
        this.cloudStorageTabFormRef = React.createRef<FormInstance>();
        const { onLoadData } = this.props;

        this.state = {
            files: {
                local: [],
            },
            active: 'local',
        };

        onLoadData('/');
    }

    public getFiles(): Files {
        const { active, files } = this.state;
        return {
            local: active === 'local' ? files.local : [],
        };
    }

    public reset(): void {
        this.setState({
            active: 'local',
            files: {
                local: [],
            },
        });
    }

    private renderLocalSelector(): JSX.Element {
        const { onUploadLocalFiles, uploadButtonDisabled } = this.props;
        const { files } = this.state;

        return (
            // <Tabs.TabPane className='cvat-file-manager-local-tab' key='local' tab='My computer'>
            //     <LocalFiles
            //         files={files.local}
            //         many={many}
            //         onUpload={(_: RcFile, newLocalFiles: RcFile[]): boolean => {
            //             this.setState({
            //                 files: {
            //                     ...files,
            //                     local: newLocalFiles,
            //                 },
            //             });
            //             onUploadLocalFiles(newLocalFiles);
            //             return false;
            //         }}
            //     />
            // </Tabs.TabPane>
            <Upload
                multiple
                listType='text'
                fileList={files.local as any[]}
                // showUploadList={
                //     files.local.length < 5 && {
                //         showRemoveIcon: false,
                //     }
                // }
                showUploadList={false}
                beforeUpload={(_: RcFile, newLocalFiles: RcFile[]): boolean => {
                    this.setState({
                        files: {
                            ...files,
                            local: newLocalFiles,
                        },
                    });
                    onUploadLocalFiles(newLocalFiles);
                    return false;
                }}
            >
                <Button icon={<UploadOutlined />} disabled={uploadButtonDisabled} />
            </Upload>
        );
    }

    public render(): JSX.Element {
        // const { onChangeActiveKey } = this.props;
        // const { active } = this.state;

        return (
            <>
                {/* <Tabs
                    type='card'
                    activeKey={active}
                    tabBarGutter={5}
                    onChange={(activeKey: string): void => {
                        onChangeActiveKey(activeKey);
                        this.setState({
                            active: activeKey as any,
                        });
                    }}
                >
                    {this.renderLocalSelector()}
                </Tabs> */}
                {this.renderLocalSelector()}
            </>
        );
    }
}

export default FileManager;
