import React, { useState, useContext, useEffect } from 'react';
import Draggable from 'react-draggable';
import { VisibilityContext, hitButtonToolbox } from './popover-context';

interface OwnProps {
    content: React.ReactNode;
    children: React.ReactNode;
    shapeType: string;
    overlayClassName?: string;
    onVisibleChange?: (visible: boolean) => void;
}

export default function CustomPopover(props: OwnProps): JSX.Element {
    const { state, dispatch } = useContext(VisibilityContext);

    const [visible, setVisible] = useState(false);

    const {
        // content, shapeType, overlayClassName, children, ...rest
        content, shapeType, overlayClassName, children, onVisibleChange,
    } = props;

    const handleVisible = (): void => {
        dispatch(hitButtonToolbox(shapeType));
    };

    // for use case when user click on button outside, highlight the button
    const blurBackgroundStyle = visible ? {
        style: { background: 'rgba(0,0,0,0.2)' },
    } : {};

    useEffect(() => {
        if (state.shapeType !== shapeType) {
            setVisible(false);
        } else { // when state.shapeType === shapeType -> toggle visible
            setVisible(!visible);
            if (onVisibleChange) onVisibleChange(!visible);
        }
    }, [state]);

    return (
        <>
            {visible &&
                (
                    <Draggable>
                        <div
                            className={overlayClassName}
                            style={{
                                position: 'fixed', top: '140px', left: '70px', zIndex: '99',
                            }}
                        >
                            {/* <div style={{ position: 'absolute' }}>
                                {content}
                            </div> */}
                            <div>
                                {content}
                            </div>
                        </div>
                    </Draggable>
                )}
            {React.cloneElement(children as React.ReactElement,
                {
                    onClick: handleVisible,
                    ...blurBackgroundStyle,
                })}
        </>
    );
}
